import React from 'react'

const ArticleTypeIcon = () => (
  <div
    style={{
      background: 'none', // Light pink background
      borderRadius: '50%', // Makes the div circular
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20vw', // Use viewport width for scalability
      height: '20vw', // Keep height equal to width for a perfect circle
      maxWidth: '51px', // Maximum size
      maxHeight: '51px',
      minWidth: '24px', // Minimum size
      minHeight: '24px',
    }}
  />
)

export default ArticleTypeIcon
