import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'antd';

const { useBreakpoint } = Grid;

function ResponsiveVisibility({ visible, hidden, children }) {
  const screens = useBreakpoint();
  let isVisible = true;

  if (visible && !screens[visible]) {
    isVisible = false;
  }

  if (hidden && screens[hidden]) {
    isVisible = false;
  }

  if (!isVisible) return null;

  return <React.Fragment>{children}</React.Fragment>;
}

ResponsiveVisibility.propTypes = {
  children: PropTypes.node,
  visible: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  hidden: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
};

export default ResponsiveVisibility;
