import { Route, Routes, Navigate } from 'react-router-dom'
import AdminLayout from '../components/layouts/AdminLayout'

import AppLayout from '../components/layouts/AppLayout'
import PublicLayout from '../components/layouts/PublicLayout'

import NotFound from '../pages/404'

// auth
import Login from '../pages/auth/Login'
import Verification from '../pages/auth/Verification'

// protection
import RequiredAuthenticationRoute from './RequiredAuthenticationRoute'
import RequiredVerificationRoute from './RequiredVerificationRoute'
import RequiredNewsletterRoute from './RequiredNewsletterRoute'
import RequiredAdminRoute from './RequiredAdminRoute'
import PrivateRoute from './PrivateRoute'

// admin
import Users from '../pages/admin/Users'
import Records from '../pages/admin/Records'
import Logs from '../pages/admin/Logs'

// metadata
import SportCategories from '../pages/admin/SportCategories'

// newsletter
import NewsletterPreferences from '../pages/newsletter/NewsletterPreferences'
import NewsletterUnsubscription from '../pages/newsletter/NewsletterUnsubscription'
import NewsletterUnsubscriptionSurvey from '../pages/newsletter/NewsletterUnsubscriptionSurvey'
import NewsletterUnsubscriptionComplete from '../pages/newsletter/NewsletterUnsubscriptionComplete'
import NewsletterFrequency from '../pages/newsletter/NewsletterFrequency'
import NewsletterRegistration from '../pages/newsletter/NewsletterRegistration'
import NewsletterSubscription from '../pages/newsletter/NewsletterSubscription'
import NewsletterComplete from '../pages/newsletter/NewsletterComplete'
import NewsletterRegistrationComplete from '../pages/newsletter/NewsletterRegistrationComplete'
import NewsletterUnsubscriptionOptions from '../pages/newsletter/NewsletterUnsubscriptionOptions'
import NewsletterRegistrationProfile from '../pages/newsletter/NewsletterRegistrationProfile'
import NewsletterRegistrationPreferences from '../pages/newsletter/NewsletterRegistrationPreferences'

import Home from '../pages/home/Home'
import Profile from '../pages/profile/Profile'

import RegisterProfile from '../pages/auth/RegisterProfile'
import PreferredSports from '../pages/profile/PreferredSports'
import ProtectedPublicLayout from '../components/layouts/ProtectedPublicLayout'
import MyTeams from '../pages/admin/Teams'

export default function Router() {
  return (
    <Routes>
      {/* Protected public routes */}
      <Route element={<ProtectedPublicLayout />}>
        <Route path="/login" element={<Login />} />
      </Route>

      {/* Public routes */}
      <Route element={<PublicLayout />}>
        <Route path="/newsletter/register" element={<NewsletterRegistration />} />
        <Route path="/newsletter/unsubscription/:token" element={<NewsletterUnsubscription />} />
        <Route path="/newsletter/register/complete" element={<NewsletterRegistrationComplete />} />
      </Route>

      {/* Required authentication routes (not verified users)*/}
      <Route
        element={
          <RequiredAuthenticationRoute>
            <PublicLayout />
          </RequiredAuthenticationRoute>
        }
      >
        <Route path="/login/verification" element={<Verification />} />
        <Route path="/newsletter/unsubscription/options" element={<NewsletterUnsubscriptionOptions />} />
        <Route path="/newsletter/unsubscription/survey" element={<NewsletterUnsubscriptionSurvey />} />
        <Route path="/newsletter/unsubscription/complete" element={<NewsletterUnsubscriptionComplete />} />
        <Route path="/newsletter/register/profile" element={<NewsletterRegistrationProfile />} />
        <Route path="/newsletter/register/preferred-sports" element={<NewsletterRegistrationPreferences />} />
      </Route>

      {/* Required authorization and verification routes (verified users) */}
      <Route
        element={
          <RequiredAuthenticationRoute>
            <RequiredVerificationRoute>
              <PublicLayout />
            </RequiredVerificationRoute>
          </RequiredAuthenticationRoute>
        }
      >
        <Route path="/register/profile" element={<RegisterProfile />} />
        <Route path="/register/preferred-sports" element={<PreferredSports />} />
      </Route>

      {/* Required authorization, verification and newsletter subscription routes (verified users with newsletter subscription) */}
      <Route
        element={
          <RequiredAuthenticationRoute>
            <RequiredVerificationRoute>
              <RequiredNewsletterRoute>
                <AppLayout />
              </RequiredNewsletterRoute>
            </RequiredVerificationRoute>
          </RequiredAuthenticationRoute>
        }
      >
        <Route path="/newsletter/preferences" element={<NewsletterPreferences />} />
        <Route path="/newsletter/frequency" element={<NewsletterFrequency />} />
        <Route path="/newsletter/complete" element={<NewsletterComplete />} />
        <Route path="/newsletter/unsubscription" element={<NewsletterUnsubscription />} />
      </Route>

      {/* Required verification and registration routes (verified users that registered to app by regular registration flow) */}
      <Route
        element={
          <PrivateRoute>
            <AppLayout />
          </PrivateRoute>
        }
      >
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/preferred-sports" element={<PreferredSports />} />
        <Route path="/newsletter/subscription" element={<NewsletterSubscription />} />
      </Route>

      {/* Required admin permission routes */}
      <Route
        element={
          <RequiredAuthenticationRoute>
            <RequiredVerificationRoute>
              <RequiredAdminRoute>
                <AdminLayout />
              </RequiredAdminRoute>
            </RequiredVerificationRoute>
          </RequiredAuthenticationRoute>
        }
      >
        <Route path="/admin" element={<Navigate to="/admin/users" replace />} />
        <Route path="/admin/users" element={<Users />} />
        <Route path="/admin/sport-categories" element={<SportCategories />} />
        <Route path="/admin/teams" element={<MyTeams />} />
        <Route path="/admin/records" element={<Records />} />
        <Route path="/admin/logs" element={<Logs />} />
      </Route>

      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  )
}
