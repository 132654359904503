import React from 'react';

const IconComponent = ({ src, icon, style = {} }) => {
  const outerCircleStyle = {
    backgroundColor: '#F3F3F3',
    padding: '5px',
    borderRadius: '50%',
    boxShadow: '0px 4px 18.6px rgba(0, 0, 0, 0.15)'
  };

  const innerCircleStyle = {
    backgroundColor: 'white',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px'
  };

  return (
    <div style={outerCircleStyle}>
      <div style={innerCircleStyle}>
        {
          icon ? React.cloneElement(icon, { style: { fontSize: '20px', ...style } }) :
            <img src={src} alt="Icon" style={{ width: '20px', height: '20px', ...style }} />
        }
      </div>
    </div>
  );
};

export default IconComponent;

