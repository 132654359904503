import React from 'react'
import { Card, Flex, Typography } from 'antd'
import ResponsiveVisibility from '../layouts/ResponsiveVisibility'
import ArticleTypeIcon from '../elements/ArticleTypeIcon'

const Article = ({ imageUrl, date, title, description, authorName, url }) => (
  <>
    <ResponsiveVisibility visible="lg">
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <DesktopArticle
          imageUrl={imageUrl}
          date={date}
          title={title}
          description={description}
          authorName={authorName}
        />
      </a>
    </ResponsiveVisibility>
    <ResponsiveVisibility hidden="lg">
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
        <MobileArticle
          imageUrl={imageUrl}
          date={date}
          title={title}
          description={description}
          authorName={authorName}
        />
      </a>
    </ResponsiveVisibility>
  </>
)

const MobileArticle = ({ imageUrl, date, title, description, authorName }) => (
  <Card hoverable cover={<img alt="Article" src={imageUrl} />}>
    <Flex align={'center'} style={{ marginBottom: '16px' }}>
      <Typography.Title
        level={4}
        style={{
          marginBottom: 0,
          marginRight: '5px',
          textAlign: 'right',
        }}
      >
        {title}
      </Typography.Title>
    </Flex>
    <Flex vertical={true} align={'flex-start'}>
      <Typography.Paragraph style={{ textAlign: 'right', fontWeight: '400' }}>{description}</Typography.Paragraph>
      <Typography.Text
        style={{
          color: 'rgba(38, 38, 38, 0.60)',
          fontWeight: '400',
        }}
      >
        {authorName} | {date}
      </Typography.Text>
    </Flex>
  </Card>
)

const DesktopArticle = ({ imageUrl, date, title, description, authorName }) => (
  <Card
    size={'small'}
    style={{ display: 'flex', border: '1px solid rgba(228, 228, 228, 0.75)', width: '740px', height: '200px' }}
    hoverable
    cover={
      <img
        alt="Article"
        width={300}
        src={imageUrl}
        style={{ objectFit: 'inherit', height: '101%', width: '290px', borderRadius: '0 12px 12px 0' }}
      />
    }
  >
    <Flex vertical={true} align={'center'} style={{ marginBottom: '10px' }}>
      <Flex align={'center'} justify={'space-between'} style={{ width: '100%' }}>
        <ArticleTypeIcon />
        <Typography.Text
          style={{
            textAlign: 'left',
            color: 'rgba(38, 38, 38, 0.60)',
            fontWeight: '400',
          }}
        >
          {authorName} | {date}
        </Typography.Text>
      </Flex>
    </Flex>
    <Typography.Title level={4} style={{ margin: 0 }}>
      {title}
    </Typography.Title>
    <Typography.Paragraph style={{ textAlign: 'right' }}>{description}</Typography.Paragraph>
  </Card>
)

export default Article
