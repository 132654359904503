import React, { useMemo } from 'react'
import { Drawer, Flex, Typography, Divider, Button, theme } from 'antd'
import { useUser } from '../../hooks/useUser'
import { CloseOutlined, LeftOutlined, ReadOutlined, ScheduleOutlined, SettingOutlined } from '@ant-design/icons'
import IconComponent from '../elements/IconComponent'
import MenuItem from '../elements/MenuItem/MenuItem'
import UserIcon from '../../assets/icons/menu-icons/user.png'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'

export default function MobileUserMenu({ isOpen, toggleDrawer }) {
  return <Sidebar isOpen={isOpen} closeSidebar={toggleDrawer} />
}

function Sidebar({ isOpen, closeSidebar }) {
  const { token } = theme.useToken()
  const { user, logOut } = useUser()
  const { newsletter } = useUserNewsletter()

  const username = useMemo(() => {
    if (!user) return
    const { email, firstName } = user
    if (firstName) return firstName

    const [username] = email.split('@')
    return username
  }, [user])

  const handleNavigationChange = () => {
    closeSidebar()
  }

  const handleLogout = () => {
    logOut()
    closeSidebar()
  }

  return (
    <Drawer
      title={null}
      width={260}
      open={isOpen}
      onClose={closeSidebar}
      styles={{
        content: {
          borderRadius: '0px 0px 0px 104px',
          height: '85vh',
        },
        header: {
          display: 'none',
        },
      }}
    >
      <Flex justify={'end'}>
        <CloseOutlined onClick={closeSidebar} style={{ color: token.colorLink, fontSize: '20px', cursor: 'pointer' }} />
      </Flex>
      <Flex vertical>
        <Flex justify={'center'} align={'center'} gap={15} vertical={true} style={{ marginTop: '15px' }}>
          <IconComponent style={{ height: '40px', width: '40px' }} src={UserIcon} />
          <Typography.Title style={{ textAlign: 'center' }} level={5}>
            היי {username || 'אורח'}
          </Typography.Title>
        </Flex>
        <Divider />
        <Flex vertical>
          {newsletter?.status !== 'active' ? (
            <MenuItem
              icon={<ReadOutlined />}
              text={'הרשמה לניוזלטר'}
              title="ניהול ניוזלטר"
              to={'/newsletter/subscription'}
              onClick={handleNavigationChange}
            />
          ) : (
            <MenuItem
              title="ניהול ניוזלטר"
              icon={<ScheduleOutlined />}
              text={'שינוי תדירות ניוזלטר'}
              to={'/newsletter/frequency'}
              onClick={handleNavigationChange}
            />
          )}
          <Divider />
          <MenuItem
            icon={<ReadOutlined />}
            text={'שינוי העדפות תוכן'}
            to={'/preferred-sports'}
            onClick={handleNavigationChange}
            title="הגדרות"
          />
          <Divider />
          <MenuItem
            icon={<SettingOutlined />}
            text={'הגדרות פרופיל'}
            to={'/profile'}
            title="הגדרות"
            onClick={handleNavigationChange}
          />
        </Flex>
      </Flex>
      <Flex vertical>
        <Divider />
        <Button onClick={handleLogout} type="text" style={{ fontSize: '18px', fontWeight: 700 }}>
          יציאה <LeftOutlined />
        </Button>
      </Flex>
    </Drawer>
  )
}
