import React, { useMemo, useState } from 'react'
import { isEmpty, debounce } from 'lodash'
import { DeleteOutlined, HolderOutlined } from '@ant-design/icons'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { Table, Row, Col, Input, Button, Modal, Form, message, Tooltip, Typography, Tag } from 'antd'
import { useSportCategories } from '../../hooks/useSportCategories'
import TableActions from '../../components/elements/TableActions'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

export default function SportCategories() {
  const [search, setSearch] = useState()
  const [isLoading, setLoading] = useState(false)
  const [isFormModalOpen, setFormModal] = useState(false)
  const [formValues, setFormValues] = useState({})
  const sportCategories = useSportCategories()
  const { data = [], updateSportCategory, deleteSportCategory } = sportCategories

  const onSearchChange = debounce((event) => {
    const { value = '' } = event.target
    setSearch(value.trim())
  }, 200)

  const onFormModalClose = () => {
    setFormValues()
    setFormModal(false)
  }

  const onFormModalOpen = (values) => {
    if (values) setFormValues(values)
    setFormModal(true)
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const { createSportCategory, updateSportCategory } = sportCategories
      const action = isEmpty(formValues) ? createSportCategory : updateSportCategory

      await action(values)
      onFormModalClose()
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const ondeleteSportCategory = async ({ _id, category }) => {
    try {
      setLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את הקטגוריה "${category}"?`,
        okText: 'אישור',
        async onOk() {
          await deleteSportCategory(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const dataSource = useMemo(() => {
    if (!search) return data
    return data.filter((item) => JSON.stringify(item).includes(search))
  }, [search, data])

  const onDragEnd = async ({ active, over }) => {
    await Promise.all([
      updateSportCategory({ _id: active.id, order: over.data.current.sortable.index }),
      updateSportCategory({ _id: over.id, order: active.data.current.sortable.index }),
    ])
  }

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext items={dataSource.map((i) => i._id)} strategy={verticalListSortingStrategy}>
        <Table
          dataSource={dataSource}
          loading={sportCategories.isLoading}
          rowKey="_id"
          bordered
          pagination={false}
          components={{
            body: {
              row: TableRow,
            },
          }}
          columns={[
            {
              key: 'sort',
            },
            {
              align: 'right',
              title: 'קטגוריה',
              width: 180,
              dataIndex: 'category',
            },
            {
              align: 'right',
              title: 'תת קטגוריות',
              dataIndex: 'topics',
              render: (topics) => topics?.map((topic) => <Tag key={topic}>{topic}</Tag>),
            },
            {
              width: 80,
              align: 'left',
              render: (record) => {
                return (
                  <TableActions
                    onDeleteClick={() => ondeleteSportCategory(record)}
                    onUpdateClick={() => onFormModalOpen(record)}
                  />
                )
              },
            },
          ]}
          title={() => (
            <Row justify="space-between" align="middle">
              <Col>
                <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
              </Col>
              <Col>
                <Button type="primary" onClick={() => onFormModalOpen()}>
                  {'הוספת קטגוריה'}
                </Button>
                <Modal
                  title={' '}
                  open={isFormModalOpen}
                  footer={null}
                  destroyOnClose
                  onCancel={onFormModalClose}
                  maskClosable={false}
                >
                  <CategoryForm onSubmit={onSubmit} initialValues={formValues} isLoading={isLoading} />
                </Modal>
              </Col>
            </Row>
          )}
        />
      </SortableContext>
    </DndContext>
  )
}

function CategoryForm({ onSubmit, initialValues = {}, isLoading }) {
  const [form] = Form.useForm()

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)
    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const topics = reorder(form.getFieldValue('topics'), result.source.index, result.destination.index)
    form.setFieldsValue({ topics })
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="category" label={'קטגוריה'}>
        <Input />
      </Form.Item>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div ref={provided.innerRef} {...provided.droppableProps}>
              <Form.List name="topics">
                {(fields, { add, remove }) => {
                  return (
                    <>
                      <Row justify="space-between">
                        <Col>
                          <Form.Item>
                            <Typography.Text>{'תת קטגוריות'}</Typography.Text>
                          </Form.Item>
                        </Col>
                        <Col>
                          <Form.Item>
                            <Button type="dashed" onClick={() => add()}>
                              הוספת תת קטגוריה
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>

                      {fields?.map((field, index) => (
                        <Draggable key={field.key} draggableId={String(field.key)} index={index}>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>
                              <Row gutter={[8, 8]}>
                                <Col>
                                  <Button type="text" {...provided.dragHandleProps}>
                                    <HolderOutlined />
                                  </Button>
                                </Col>
                                <Col flex={1}>
                                  <Form.Item
                                    {...field}
                                    style={{ marginBottom: 8, width: '100%' }}
                                    rules={[{ required: true, message: 'שדה חובה' }]}
                                  >
                                    <Input />
                                  </Form.Item>
                                </Col>
                                <Col>
                                  <Form.Item style={{ marginBottom: 8 }}>
                                    <Tooltip title={'מחיקה'}>
                                      <Button onClick={() => remove(field.name)} type="text">
                                        <DeleteOutlined />
                                      </Button>
                                    </Tooltip>
                                  </Form.Item>
                                </Col>
                              </Row>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </>
                  )
                }}
              </Form.List>
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}

function TableRow({ children, ...props }) {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
    id: props['data-row-key'],
  })
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  }
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <HolderOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
