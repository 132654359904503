import axios from 'axios'
import { useQuery } from 'react-query'
import { useUser } from './useUser'
import { useExposebox } from './useExposebox'
import { track } from '../utils/analytics'

const endpoint = '/api/user/newsletter'

export function useUserNewsletter() {
  const { user } = useUser()
  const exposebox = useExposebox()

  const queryFn = async () => {
    const { data } = await axios.get(endpoint)
    return data
  }

  const {
    isLoading,
    data: newsletter = {},
    refetch,
    isFetched,
  } = useQuery({
    queryKey: [endpoint, user?.email],
    queryFn,
    enabled: !!user?.email,
  })

  const updateNewsletterSubscriptions = async (payload) => {
    await axios.put(`${endpoint}/subscriptions`, payload)
    await refetch()
  }

  const updateNewsletterFrequency = async (payload) => {
    await axios.put(`${endpoint}/frequency`, payload)
    track({
      event_name: 'form_submit',
      form_name: 'frequency_selection',
      flow: 'main',
      purpose: 'frequency',
      type: 'frequency_update',
    })
    await refetch()
  }

  const updateNewsletterStatus = async (status, flow) => {
    if (status === 'active') {
      const { data: unsubscriptionToken } = await axios.get(endpoint + '/unsubscription-token')

      exposebox.setCustomerData({
        customerId: user.email,
        email: user.email,
        subscribedUserLists: ['master-email'],
        freeDataPatch: {
          unsubscriptionToken,
        },
      })

      track({ event_name: 'newsletter_subscribe', purpose: 'approve_subscription', flow })
    }

    await axios.put(`${endpoint}/status`, { status })
    await refetch()
  }

  const updateNewsletterFreez = async (payload) => {
    await axios.put(`${endpoint}/freez`, payload)
  }

  return {
    isLoading,
    isFetched,
    newsletter,
    updateNewsletterSubscriptions,
    updateNewsletterFrequency,
    updateNewsletterFreez,
    updateNewsletterStatus,
  }
}
