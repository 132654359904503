import { useEffect } from 'react'
import { Row, Col, Typography } from 'antd'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import { useExposebox } from '../../hooks/useExposebox'
import { useUser } from '../../hooks/useUser'
import ContentCard from '../../components/layouts/ContentCard'
import NewsletterCategoriesForm from './components/NewsletterCategoriesForm'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function NewsletterRegistrationPreferences() {
  const { user } = useUser()
  const userNewsletter = useUserNewsletter()
  const exposebox = useExposebox()
  const { newsletter = {} } = userNewsletter
  const { status } = newsletter
  const { setCustomerData } = exposebox

  useEffect(() => {
    if (user?.isVerified && status === 'active') {
      setCustomerData({ customerId: user.email })
    }
  }, [user, status, setCustomerData])

  return (
    <Row className="bg bg5" justify="center">
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard loading={userNewsletter.isLoading}>
          <Row gutter={[24, 24]}>
            <Col flex="600px">
              <Typography.Title level={3} style={{ marginBottom: 12 }}>
                <span>{'איזה ענפי ספורט מעניינים אותך?'}</span>
                <br />
                <span>{'(בחירה מרובה)'}</span>
              </Typography.Title>
            </Col>
            <Col xs={24}>
              <NewsletterCategoriesForm
                submitText="המשך"
                gaFlow={'newletter'}
                postSubmitRedirectUrl={'/newsletter/register/complete'}
              />
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
