import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { Col, Flex, Row, notification, Typography } from 'antd'
import { useUserNewsletter } from '../../hooks/useUserNewsletter'
import Articles from '../../components/articles/Articles'
import { useBlog } from '../../hooks/useBlog'
import HeaderBenefit from '../../components/elements/HeaderBenefit'
import dayjs from 'dayjs'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

const benefitUrl =
  'https://my.sport5.co.il/newsletter/register?utm_source=sport5&utm_medium=owned&utm_content=red_header_personal_area&utm_campaign=newsletter'
export default function Home() {
  useUserNewsletter()
  const { isLoading, data, fetchNextPage, hasNextPage } = useBlog()
  const location = useLocation()

  useEffect(() => {
    if (location.state?.isWelcomePopup) {
      window.history.replaceState({}, '')
      notification.open({
        icon: false,
        description: (
          <Flex vertical justify="center" align="center" gap={12}>
            <img src={'/icons/ok.svg'} alt="success" width={44} />
            <Typography.Title level={1} style={{ margin: 0 }}>
              {'קיבלנו!'}
            </Typography.Title>
            <Typography.Title level={4} style={{ margin: 0 }}>
              {'ברוך הבא לאזור האישי :)'}
            </Typography.Title>
          </Flex>
        ),
      })
    }
  }, [location])

  const formattedArticles =
    data?.pages
      .flatMap((page) => page.blogs)
      .map((blog) => ({
        ...blog,
        publishDate: dayjs(blog.publishDate, 'x').format('DD.MM.YY'),
        imageUrl: blog.image,
        description: blog.subTitle,
        authorName: blog.author,
        url: blog.url,
      })) || []

  return (
    <Row justify="center" className="bg bg4">
      <HelmetHeader />
      <Col flex="740px">
        <Flex vertical={true} gap={10} style={{ marginTop: 10 }}>
          <HeaderBenefit benefitImageSrc={'/images/newsletter-cta.png'} benefitUrl={benefitUrl} />
          <Articles
            articles={formattedArticles}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            isLoading={isLoading}
          />
        </Flex>
      </Col>
    </Row>
  )
}
