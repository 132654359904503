import React, { useRef, useEffect, useCallback } from 'react'
import Article from './Article'
import { Row, Col, Card, Typography, theme } from 'antd'

const Articles = ({ articles, fetchNextPage, hasNextPage, isLoading }) => {
  const { token } = theme.useToken()

  const observer = useRef()
  const lastArticleRef = useCallback(
    (node) => {
      if (isLoading) return
      if (observer.current) observer.current.disconnect()

      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage) {
            fetchNextPage()
          }
        },
        { rootMargin: '100px' },
      )

      if (node) observer.current.observe(node)
    },
    [isLoading, hasNextPage, fetchNextPage],
  )

  useEffect(() => {
    return () => {
      if (observer.current && lastArticleRef.current) {
        observer.current.unobserve(lastArticleRef.current)
      }
    }
  }, [])

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <Card 
          style={{ background: token.colorPrimary }}
          styles={{
            body: {
              padding: 12
            }
          }}
        >
          <Typography.Title level={3} style={{ margin: 0, textAlign: 'center', color: 'white' }}>{'תוכן אקסקלוסיבי'}</Typography.Title>
        </Card>
      </Col>
      {articles.map((article) => (
        <Col xs={24}>
          <Article
            key={article?.url}
            imageUrl={article?.image}
            date={article?.publishDate}
            title={article?.title}
            description={article?.subTitle}
            authorName={article?.author}
            url={article?.url}
          />
        </Col>
      ))}
      <Col xs={24}>{hasNextPage && <div ref={lastArticleRef} style={{ height: 20, background: 'transparent' }} />}</Col>
    </Row>
  )
}

export default Articles
