import { useMemo, useState } from 'react'
import { isEmpty, debounce } from 'lodash'
import { Table, Input, Button, Modal, message, Tag, Select, Flex, Avatar } from 'antd'
import TableActions from '../../components/elements/TableActions'
import { useTeams } from '../../hooks/useTeams'
import { TeamForm } from './components/TeamForm'

const categoryDictionary = {
  israeliFootball: 'כדורגל ישראלי',
  worldFootball: 'כדורגל עולמי',
  basketball: 'כדורסל',
}
const selectOptions = [
  { value: 'allTeams', label: 'כל הקבוצות' },
  { value: 'israeliFootball', label: 'כדורגל ישראלי' },
  { value: 'worldFootball', label: 'כדורגל עולמי' },
  { value: 'basketball', label: 'כדורסל' },
]

export default function Teams() {
  const [search, setSearch] = useState('')
  const [selectedCategory, setSelectedCategory] = useState('allTeams')
  const [isLoading, setLoading] = useState(false)
  const [isFormModalOpen, setFormModal] = useState(false)
  const [formValues, setFormValues] = useState({})

  const { data = [], isLoading: isTeamLoading, createTeam, deleteTeam, updateTeam } = useTeams()

  const filteredData = useMemo(
    () =>
      data?.filter((team) => {
        if (selectedCategory === 'allTeams') return team.name.includes(search)
        return team.category === selectedCategory && team.name.includes(search)
      }),
    [data, search, selectedCategory],
  )

  const onSearchChange = debounce((event) => {
    const { value = '' } = event.target
    setSearch(value.trim())
  }, 200)

  const onSelectChange = (value) => {
    setSelectedCategory(value)
  }

  const onFormModalClose = () => {
    setFormValues()
    setFormModal(false)
  }

  const onFormModalOpen = (values) => {
    if (values) setFormValues(values)
    setFormModal(true)
  }

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const action = isEmpty(formValues) ? createTeam : updateTeam

      await action(values)
      onFormModalClose()
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  const ondeleteTeam = async ({ _id, name }) => {
    try {
      setLoading(true)

      Modal.confirm({
        title: 'מחיקה',
        content: `האם למחוק את הקבוצה "${name}"?`,
        okText: 'אישור',
        async onOk() {
          await deleteTeam(_id)
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      <Table
        dataSource={filteredData}
        loading={isTeamLoading}
        rowKey="_id"
        bordered
        pagination={{
          pageSize: 10,
        }}
        columns={[
          {
            key: 'logo',
            title: 'לוגו',
            width: 80,
            dataIndex: 'iconUrl',
            render: (icon) => <Avatar src={icon} shape="square" size="large" />,
          },
          {
            title: 'שם הקבוצה',
            width: 150,
            dataIndex: 'name',
          },
          {
            title: 'קטגוריה',
            width: 120,
            dataIndex: 'category',
            render: (topic) => <Tag key={topic}>{categoryDictionary[topic]}</Tag>,
          },
          {
            title: 'תגיות',
            dataIndex: 'tags',
            render: (tags) => tags?.map((tag) => <Tag key={tag}>{tag}</Tag>),
          },
          {
            width: 80,
            render: (record) => {
              return (
                <TableActions
                  onDeleteClick={() => ondeleteTeam(record)}
                  onUpdateClick={() => onFormModalOpen(record)}
                />
              )
            },
          },
        ]}
        title={() => (
          <Flex justify="space-between" align="middle">
            <Flex gap={14}>
              <Input.Search onChange={onSearchChange} placeholder={'חיפוש'} allowClear />
              <Select
                style={{ width: 180, minWidth: 180 }}
                onChange={onSelectChange}
                options={selectOptions}
                value={selectedCategory}
              />
            </Flex>
            <Flex>
              <Button type="primary" onClick={() => onFormModalOpen()}>
                {'הוספת קבוצה'}
              </Button>
              <Modal
                title={' '}
                open={isFormModalOpen}
                footer={null}
                destroyOnClose
                onCancel={onFormModalClose}
                maskClosable={false}
              >
                <TeamForm onSubmit={onSubmit} initialValues={formValues} isLoading={isLoading} />
              </Modal>
            </Flex>
          </Flex>
        )}
      />
    </div>
  )
}
