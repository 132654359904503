import { useState } from 'react'
import { Row, Col, Typography, Flex, message } from 'antd'
import ContentCard from '../../components/layouts/ContentCard'
import { useNavigate } from 'react-router-dom'
import BackButton from '../../components/elements/BackButton'
import SportCategoriesForm from '../../components/forms/SportCategoriesForm'
import { useUpdateUserPreferredSports } from '../../hooks/usePreferredSports'
import { track } from '../../utils/analytics'
import { HelmetHeader } from '../../components/elements/HelmetHeader'

export default function PreferredSports() {
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const { updatePreferredSports } = useUpdateUserPreferredSports()

  const onSubmit = async (checkedNodes) => {
    try {
      setIsLoading(true)
      const checkedKeys = checkedNodes.map((node) => node.key)
      await updatePreferredSports(checkedKeys)

      track({
        event_name: 'form_submit',
        form_name: 'interests_selection',
        flow: 'main',
        purpose: 'interests',
      })

      navigate('/', {
        state: {
          isWelcomePopup: true,
        },
      })
    } catch (error) {
      message.error(error.message)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Row className="bg bg5" justify="center" style={{ minHeight: '100vh' }}>
      <HelmetHeader />
      <Col align="middle" flex="724px">
        <ContentCard
          title={
            <Flex>
              <BackButton />
            </Flex>
          }
        >
          <Row gutter={[24, 24]}>
            <Col flex="600px">
              <Typography.Title level={3} style={{ marginBottom: 12 }}>
                <span>{'איזה ענפי ספורט מעניינים אותך?'}</span>
                <br />
                <span>{'(בחירה מרובה)'}</span>
              </Typography.Title>
            </Col>
            <Col xs={24}>
              <SportCategoriesForm
                onSubmit={onSubmit}
                submitText={'המשך'}
                gaFlow="main"
                isLoading={isLoading}
              />
            </Col>
          </Row>
        </ContentCard>
      </Col>
    </Row>
  )
}
