import { DeleteOutlined } from '@ant-design/icons'
import { Row, Col, Form, Input, Button, Tooltip, Typography, Select } from 'antd'
import { UploadImage } from './UploadImage'
import axios from 'axios'
import { useState } from 'react'
import { isEmpty } from 'lodash'
const config = {
  headers: {
    'Content-Type': 'multipart/form-data',
  },
}

export const TeamForm = ({ onSubmit, initialValues = {}, isLoading }) => {
  const [form] = Form.useForm()
  const [imageUrl, setImageUrl] = useState('')

  const getIconUrl = () => {
    if (imageUrl !== '') return imageUrl
    return !isEmpty(initialValues) ? initialValues.iconUrl : ''
  }

  const uploadToCloudinary = async ({ file }) => {
    const formData = new FormData()
    formData.append('file', file)
    formData.append('upload_preset', 'my_sport5')

    try {
      const response = await axios.post('https://api.cloudinary.com/v1_1/rge1/image/upload', formData, config)

      setImageUrl(response.data.secure_url)
      form.setFieldValue('iconUrl', response.data.secure_url)
    } catch (error) {
      console.log('error ', error)
    }
  }

  return (
    <Form layout="vertical" form={form} onFinish={onSubmit} initialValues={initialValues}>
      <Form.Item hidden name="_id">
        <Input />
      </Form.Item>
      <Form.Item name="iconUrl" valuePropName="fileList" required label={'לוגו'}>
        <UploadImage uploadToCloudinary={uploadToCloudinary} iconUrl={getIconUrl()} />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="category" label={'קטגוריה'}>
        <Select
          defaultValue="worldFootball"
          options={[
            { value: 'worldFootball', label: 'כדורגל עולמי' },
            { value: 'israeliFootball', label: 'כדורגל ישראלי' },
            { value: 'basketball', label: 'כדורסל' },
          ]}
        />
      </Form.Item>
      <Form.Item rules={[{ required: true }]} name="name" label={'שם הקבוצה'}>
        <Input />
      </Form.Item>
      <Form.List name="tags">
        {(tags, { add, remove }) => {
          return (
            <>
              <Row justify="space-between">
                <Col>
                  <Form.Item>
                    <Typography.Text>{'תגיות'}</Typography.Text>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item>
                    <Button type="dashed" onClick={() => add()}>
                      הוספת תגית
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              {tags?.map((tag) => (
                <Row gutter={[8, 8]}>
                  <Col flex={1}>
                    <Form.Item
                      {...tag}
                      style={{ marginBottom: 8, width: '100%' }}
                      rules={[{ required: true, message: 'שדה חובה' }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item style={{ marginBottom: 8 }}>
                      <Tooltip title={'מחיקה'}>
                        <Button onClick={() => remove(tag.name)} type="text">
                          <DeleteOutlined />
                        </Button>
                      </Tooltip>
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </>
          )
        }}
      </Form.List>
      <Button block loading={isLoading} style={{ marginTop: 24 }} type="primary" htmlType="submit">
        {'שמירה'}
      </Button>
    </Form>
  )
}
