import { useQuery } from 'react-query'
import axios from 'axios'

const endpoint = '/api/sport-categories'

export function useSportCategories() {
  const {
    isLoading,
    data: response = {},
    refetch,
  } = useQuery({
    queryKey: [endpoint],
    queryFn: () => axios.get(endpoint),
  })

  const createSportCategory = async (values) => {
    await axios.post(endpoint, values)
    refetch()
  }

  const deleteSportCategory = async (id) => {
    await axios.delete(`${endpoint}/${id}`)
    refetch()
  }

  const updateSportCategory = async (values) => {
    await axios.put(`${endpoint}/${values._id}`, values)
    refetch()
  }

  const { data = [] } = response

  return {
    isLoading,
    data,
    createSportCategory,
    deleteSportCategory,
    updateSportCategory,
  }
}
